import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import VendorTable from "./VendorTable.js";

function VendorView() {
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const [stocks, setStocks] = useState([]);
  const [products, setProducts] = useState([]);
  const [stockDialogOpen, setStockDialogOpen] = useState(false);

  // Fetch vendors
  const getVendors = async () => {
    try {
      setLoading(true);
      const hospitalid = localStorage.getItem("hospitalid");
      const url = `${process.env.REACT_APP_BASE_URL}vendors/${hospitalid}`;
      const { data } = await axios.get(url);
      setVendors(
        data.vendors.map((vendor) => ({
          ...vendor,
          handleShowStock: handleViewStocks,
          handleShowProducts: handleViewProducts,
        }))
      );
      setLoading(false);
    } catch (error) {
      console.error("Error fetching vendors:", error);
      setLoading(false);
    }
  };

  // Fetch stocks for a vendor
  const handleViewStocks = async (vendorId) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}stocks/vendor/${vendorId}`;
      const { data } = await axios.get(url);

      // Filter stocks to include only those with totalquantity > 0
      const availableStocks = data.stocks.filter((stock) => +stock.totalquantity > 0);

      setStocks(availableStocks); // Set the filtered stocks
      setStockDialogOpen(true); // Open the stock dialog
      setLoading(false);
    } catch (error) {
      console.error("Error fetching stocks:", error);
      setLoading(false);
    }
  };

  // Fetch products for a vendor
  const handleViewProducts = async (vendorId) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}products/vendor/${vendorId}`;
      const { data } = await axios.get(url);

      setProducts(data.products); // Set the fetched products
      setProductDialogOpen(true); // Open the product dialog
      setLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setLoading(false);
    }
  };

  // Close the stock dialog
  const handleCloseStockDialog = () => {
    setStockDialogOpen(false);
    setStocks([]);
  };

  // Close the product dialog
  const handleCloseProductDialog = () => {
    setProductDialogOpen(false);
    setProducts([]);
  };

  useEffect(() => {
    getVendors();
  }, []);

  return (
    <div className="vendor-view-container">
      <VendorTable
        handleShowStock={handleViewStocks}
        loading={loading}
        vendors={vendors}
        handleShowProduct={handleViewProducts}
      />
      {/* Dialog for Stock Details */}
      <Dialog open={stockDialogOpen} onClose={handleCloseStockDialog} maxWidth="md" fullWidth>
        <DialogTitle>Stock Details</DialogTitle>
        <DialogContent>
          {stocks.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Product Name</TableCell>
                    <TableCell align="center">Batch No</TableCell>
                    <TableCell align="center">Unit Cost</TableCell>
                    <TableCell align="center">Total Quantity</TableCell>
                    <TableCell align="center">Date of Expiry</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stocks.map((stock) => (
                    <TableRow key={stock._id}>
                      <TableCell align="center">{stock.productid?.name}</TableCell>
                      <TableCell align="center">{stock.batchno}</TableCell>
                      <TableCell align="center">{stock.unitcost}</TableCell>
                      <TableCell align="center">{stock.totalquantity}</TableCell>
                      <TableCell align="center">{stock.doe}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <p>No stocks found for this vendor.</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStockDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog for Product Details */}
      <Dialog open={productDialogOpen} onClose={handleCloseProductDialog} maxWidth="md" fullWidth>
        <DialogTitle>Product Details</DialogTitle>
        <DialogContent>
          {products.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Product Name</TableCell>
                    <TableCell align="center">UPC Code</TableCell>
                    <TableCell align="center">Manufacturer</TableCell>
                    <TableCell align="center">Origin</TableCell>
                    <TableCell align="center">Emergency Type</TableCell>
                    <TableCell align="center">Category</TableCell>
                    <TableCell align="center">Subcategory</TableCell>
                    {/* <TableCell align="center">Description</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((product) => (
                    <TableRow key={product._id}>
                      <TableCell align="center">{product.name}</TableCell>
                      <TableCell align="center">{product.upccode}</TableCell>
                      <TableCell align="center">{product.manufacturer}</TableCell>
                      <TableCell align="center">{product.origin}</TableCell>
                      <TableCell align="center">{product.emergencytype}</TableCell>
                      <TableCell align="center">{product.category?.name}</TableCell>
                      <TableCell align="center">{product.subcategory?.name}</TableCell>
                      {/* <TableCell align="center">{product.description}</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <p>No products found for this vendor.</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseProductDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default VendorView;
