import { Button } from "@material-ui/core";
import { Stack } from "@mui/material";

const width = 160;
const disableColumnMenu = true;

export const vendorListColDef = [
  {
    field: "name",
    headerName: "Vendor Name",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "phone",
    headerName: "Phone",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "address",
    headerName: "Address",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "district",
    headerName: "District",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "state",
    headerName: "State",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "gstno",
    headerName: "GST Number",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 300, // Adjusted width to fit both buttons
    align: "left",
    renderCell: (params) => (
      <Stack direction="row" spacing={2}>
        {/* View Stocks Button */}
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            params.row.handleShowStock(params.row._id); // Trigger the stock view function
          }}
        >
          Avalaible Stocks
        </Button>

        {/* View Products Button */}
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            params.row.handleShowProduct(params.row._id); // Trigger the product view function
          }}
        >
          View Products
        </Button>
      </Stack>
    ),
  },
];
