import "../Dashboard/Dashboard.css";

import TotalProductTable from "./TotalProductTable";

function TotalProduct({ heading }) {
  const hospitalid = localStorage.getItem("hospitalid");

  return <TotalProductTable hospitalid={hospitalid} heading={heading} />;
}

export default TotalProduct;
