import Box from "@mui/material/Box";
import Header from "../Dashboard/Components/header.js";
import NewSidebar from "../Dashboard/new_sidebar.js";
import { useState, useEffect } from "react";
import VendorView from "./VendorView.js";

function VendorViewScreen() {
  const isIm = localStorage.getItem("inventorymanagerid") !== null;

  return (
    <Box
      sx={{
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <VendorView />
      </Box>
  );
}

export default VendorViewScreen;
