import * as Yup from "yup";

const nameRegex = new RegExp("^[a-zA-Z0-9 ]+$");

export const ProductSchema = Yup.object({
  //producttype: Yup.string().required("Select Your Product Type"),
  // category: Yup.string().required("Please Select Product Category"),
  upccode: Yup.string().length(12).required("Please Enter UPC Manually"),
  name: Yup.string()
    .min(2)
    .max(100)
    .matches(nameRegex, "Product name can not have special characters.")
    .required("Please enter Product Name"),
  manufacturer: Yup.string()
    .min(3)
    .max(100)
    .required("Please enter Manufacturer Name"),
  // emergencytype: Yup.string().required("Please Select Emergency Type"),
  description: Yup.string()
    .min(10)
    .required("Please enter Product Description"),
  productImage: Yup.mixed().required("Please add a product image"),

  //repassword: Yup.string()
  //.required("Confirm password is required")
  //.oneOf([Yup.ref("password"), null], "Password must match"),
});
