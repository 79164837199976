import { Button } from "@mui/material";

const disableColumnMenu = true;
let width = 180;
const isIm = localStorage.getItem("inventorymanagerid") !== null;

// if (!isIm) {
//   width = 145;
// }

const columnDefinations = [
  {
    field: "name",
    headerName: "PRODUCT",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "type",
    headerName: "PPRODUCT TYPE",
    headerAlign: "left",
    width,
    align: "left",
    editable: true,
  },
  // { field: "batchno", headerName: "BATCH NO", width, editable: true },
  {
    field: "manufacturer",
    headerName: "MANUFACTURER",
    width,
    disableColumnMenu,
  },
  {
    field: "category",
    headerName: "CATEGORY",
    width,
  },
  // { field: "unitcost", headerName: "UNIT COST", width, editable: true },
  {
    field: "emergencytype",
    headerName: "EMERGENCY TYPE",
    width,
  },
];

if (!isIm) {
  columnDefinations.splice(1, 0, {
    field: "imname",
    headerName: "INVENTORY MANAGER",
    width: 200,
    align: "left",
    headerAlign: "left",
    disableColumnMenu,
  });
}

if (!isIm) {
  columnDefinations.push({
    field: "actions",
    headerName: "ACTIONS",
    width,
    disableColumnMenu,
    renderCell: (params) => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          // color="primary"
          disabled={
            params.row.actions.type === "Order" ||
            params.row.actions.type === "Pending" ||
            params.row.actions.type === "Order-Processing" ||
            params.row.actions.type === "Order-Shipped" ||
            params.row.actions.type === "Order-Delivered" ||
            params.row.actions.type === "Order-Completed" 
          }
          onClick={() => params.row.actions.onClick()}
          // onClick={() => console.log(params.row)}
          sx={{ backgroundColor: "#2e718a" }}
        >
          Order
        </Button>
      </div>
    ),
  });
}
export { columnDefinations };
