import Axios from "axios";
import { useState, React, useEffect } from "react";
import { useFormik } from "formik";
import { MenuItem, Button, Box, Select, InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PopupMessage from "../PopupMessage/PopupMessage.js";
import "./StockEntry.css";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
// Search functionality
import SearchIcon from "@mui/icons-material/Search";
import styled from "styled-components";
import fetchSearchResults from "../utils/fetchSearchResults.js";
import dayjs from "dayjs";

import AlertDialog from "../UI/AlertDialog.js";
import LoaderOverlay from "../Loader/LoaderOverlay.js";

const isInventoryManager = localStorage.getItem("inventorymanagerid") !== null;
const imID = localStorage.getItem("inventorymanagerid");

const initialValues = {
  productname:"",
  upccode:"",
  manufacturer:"",
  producttype:"",
  category:"",
  productid: "",
  name: "",
  phone: "",
  batchno: "",
  unitcost: "",
  totalquantity: "",
  doe: "",
  dom: "",
  productImage:null,
};



const StockEdit = () => {
  const hospitalid = localStorage.getItem("hospitalid");
  const location = useLocation();
  const { state } = location;
  const { stockid } = state || {};
  console.log("Stock id  is "+stockid);
  const [gst, setGST] = useState(""); // Add a new state variable for GST rate
  const [grandtotal, setGrandTotal] = useState(0); // Add a new state variable for
  const [category, setCategory] = useState(null);
  const [manufacturer, setManufacturer] = useState(null);
  const [upc, setUpc] = useState(null);
  const [type, setType] = useState(null);
  const [id, setId] = useState(null);
  const [doe, setDoe] = useState(null);
  const [dom, setDom] = useState(null);
  const [open, setOpen] = useState(false);
  const [isStockRegistered, setIsStockRegistered] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [productImage, setProductImage] = useState(null);
  const [stockEntries, setStockEntries] = useState([]);
  const [stockId, setStockId] = useState([]);
  const [stockProductArray, setStockProductArray] = useState([]);
  const [existQuantity, setExistQuantity] = useState([]);
  const [name, setName] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [quantityError, setQuantityError] = useState("");
  let [loading, setLoading] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [initialproductname, setInitialProductName] = useState("");
const [initialupccode, setInitialUpcCode] = useState("");
const [initialmanufacturer, setInitialManufacturer] = useState("");
const [initialproducttype, setInitialProductType] = useState("");
const [initialcategory, setInitialCategory] = useState("");
const [initialproductid, setInitialProductId] = useState("");
const [initialname, setInitialName] = useState("");
const [initialphone, setInitialPhone] = useState("");
const [initialbatchno, setInitialBatchNo] = useState("");
const [initialunitcost, setInitialUnitCost] = useState("");
const [initialtotalquantity, setInitialTotalQuantity] = useState("");
const [initialgrandtotal, setInitialGrandTotal] = useState("0");
const [initialdoe, setInitialDoe] = useState("");
const [initialdom, setInitialDom] = useState("");
const [initialgst,setInitialGst] = useState("");
const [initialproductImage, setInitialProductImage] = useState(null);
const [productid, setProductId] = useState("");
const [initialbuffer, setInitialBuffer] = useState("");


  // Handle search input changes
 
  
  const fieldLabels = {
    name: "Name",
    phone: "Phone Number",
    batchno: "Batch Number",
    unitcost: "Unit Cost",
    totalquantity: "Total Quantity",
    gst: "GST Slab %",
    dom: "Date of Manufacturing",
    doe: "Date of PM",
    productname: "Select Product Name",
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const StockSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string()
      .required("Phone Number is required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Enter Valid Phone Number")
      .max(10, "Enter Valid Phone Number"),
    batchno: Yup.string().required("Batch Number is required"),
    unitcost: Yup.number()
      .typeError("Unit cost must be a number")
      .required("Unit cost is required"),
    totalquantity: Yup.number()
      .typeError("Total quantity must be a number")
      .required("Total quantity is required"),
    gst: Yup.string().required("Select Your GST Slab"),
    dom: Yup.string().required("Date of Manufacture is required"),
    doe: Yup.string().required("Date of PM is required"),
    productname: Yup.string().required("Product Name is required"),
  });

  const bufferToBase64 = (buf) => {
    let binary = "";
    const bytes = [].slice.call(new Uint8Array(buf));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  };


 
  useEffect(() => {
    if (isStockRegistered) {
      const timer = setTimeout(() => {
        //window.location.reload(); // Reload the page after the desired delay
      }, 2000); // Adjust the delay as needed (in milliseconds)

      return () => clearTimeout(timer);
    }
  }, [isStockRegistered]);
  const getstock = async () => {

    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}stockbyid/${stockid}`;

      const { data } = await axios.get(url);
      console.log("data is "+ data.stock.productdetails.name)
      setLoading(false);
       const stocks = data.stock;
       setInitialProductId(stocks.productdetails._id)
       setInitialProductName(stocks.productdetails.name);
       setInitialCategory(stocks.productdetails.subcategory);
       setInitialUpcCode(stocks.productdetails.upccode);
       setInitialManufacturer(stocks.productdetails.manufacturer);
       setInitialProductType(stocks.productdetails.producttype)
       setInitialName(stocks.vendorName);
       setInitialPhone(stocks.vendorPhone);
       setInitialBatchNo(stocks.batchno);
       setInitialUnitCost(stocks.unitcost);
       setInitialTotalQuantity(stocks.totalquantity);
       setInitialDoe(stocks.doe);
       setInitialDom(stocks.dom);
       setInitialGst(stocks.gst);
       setInitialGrandTotal(stocks.grandtotal);
       setInitialBuffer(stocks.buffervalue);
       console.log("productid"+stocks.productdetails._id);
       const imageData = stocks.productdetails.productImage;
       if (imageData) {
        if (imageData.data) {
            // Case 1: Buffer format (convert to Base64)
            const base64String = bufferToBase64(imageData.data);
            setInitialProductImage(`data:image/jpeg;base64,${base64String}`);
        } else if (typeof imageData === "string") {
            // Case 2: Already Base64 (use directly)
            setInitialProductImage(`data:image/jpeg;base64,${imageData}`);
        } else {
            setInitialProductImage(null);
        }
    } else {
        setInitialProductImage(null);
    }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getstock();

  }, [initialproductname == null]); // It runs when initialproductname is updated
 


  function checkStockExists(batchno, productid, hospitalid) {
    return fetch(
      `${process.env.REACT_APP_BASE_URL}api/check-stock?batchno=${batchno}&productid=${productid}&hospitalid=${hospitalid}`,
    )
      .then((response) => response.json())
      .then((data) => {
        return data.exists; // Return true if stock exists, false otherwise
      })
      .catch((error) => {
        console.error("Error checking stock:", error);
        return false; // Handle error by returning false
      });
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      batchno: "",
      unitcost: "",
      totalquantity: "",
      gst: initialgst || "", // Use initialgst, default to empty if undefined
      dom: "",
      doe: "",
      productname: "",
      backDate: "",
    },
    validationSchema: StockSchema,
    // validateOnChange: false,  // Disables validation on field change
    // validateOnBlur: false,
    onSubmit: (values, action) => {
      const hospitalid = localStorage.getItem("hospitalid");
      console.log(
        "Query Parameters:" + values.batchno + " " + id + " " + hospitalid,
      );

      checkStockExists(values.batchno, id, hospitalid).then((stockExists) => {
        console.log("Stock status" + stockExists);
        if (stockExists) {
          // Show dialog alert saying product with same batchno exists
          setShowAlertDialog(true);
          setAlertText("Product with the same batch number already exists.");
        } else {
          // Proceed with adding the stock entry
          let exist = false;
          let currStockId = null;
          let currentQuantity = null;

          for (let i = 0; i < stockProductArray.length; i++) {
            if (stockProductArray[i] === id) {
              exist = true;
              currStockId = stockId[i];
              currentQuantity = existQuantity[i];
              break;
            }
          }

          const stockEntry = {
            hospitalid,
            productid: id,
            name: values.name,
            phone: values.phone,
            batchno: values.batchno,
            unitcost: values.unitcost,
            totalquantity: values.totalquantity,
            gst: values.gst,
            grandtotal: grandtotal, // Calculate the grand total by adding 15% GST
            buffervalue: +values.totalquantity * 0.15,
            doe: values.doe,
            dom: values.dom,
            upccode: upc,
            productname: values.productname,
            manufacturer: manufacturer,
            exist,
            currStockId,
            currentQuantity,
            backDate: values.backDate,
          };

          console.log("Stock Entry:", stockEntry);
          setStockEntries([...stockEntries, stockEntry]);

          // Reset the form fields
          action.resetForm();
          setUpc("");
          setManufacturer("");
          setType("");
          setCategory("");
          setDoe(null);
          setDom(null);
          setProductImage(null);
        }
      });
    },
  });

  const handleSubmitAllProducts = async () => {
    // setLoading(true);
    try {
      const buffervalue = +formik.values.totalquantity * 0.15;
      const formData = new FormData();
      formData.append("stockid", stockid);
      formData.append("hospitalid", localStorage.getItem("hospitalid"));
      formData.append("productid", initialproductid);
      formData.append("imid", localStorage.getItem("inventorymanagerid")); 
      formData.append("batchno", formik.values.batchno || initialbatchno);
      formData.append("unitcost", formik.values.unitcost || initialunitcost);
      formData.append("totalquantity", formik.values.totalquantity || initialtotalquantity);
      formData.append(
        "gst",
        formik.values.gst || initialgst,
      );
      formData.append("grandtotal", grandtotal > 0 ? grandtotal : initialgrandtotal);
      formData.append("buffervalue", buffervalue || initialbuffer);
      formData.append(
        "doe",
        formik.values.doe || initialdoe,
      );
      formData.append(
        "dom",
        formik.values.dom || initialdoe,
      );
      formData.append("vendorName", formik.values.name || initialname)
      formData.append("vendorPhone", formik.values.phone || initialphone)


      console.log(
        "formdata is:" +
          formData.get("stockid") +
          formData.get("hospitalid") +
          formData.get("imid")+
          formData.get("productid") +
          formData.get("batchno") +
          formData.get("unitcost") +
          formData.get("totalquantity") +
          formData.get("gst") +
          formData.get("grandtotal") +
          formData.get("buffervalue"),
          formData.get("doe")+
          formData.get("dom")+
          formData.get("vendorName")+
          formData.get("vendorPhone")

      );

      const demand = {
        stockid: stockid.toString(),
        hospitalid: localStorage.getItem("hospitalid"),
        productid: productid ,
        imid: localStorage.getItem("inventorymanagerid"),
        batchno: formik.values.batchno || initialbatchno,
        unitcost: formik.values.unitcost || initialunitcost,
        upccode: formik.values.upccode || initialupccode,
        totalquantity: formik.values.totalquantity || initialtotalquantity,
        gst: formik.values.gst || initialgst,
        grandtotal: grandtotal,
        buffervalue: buffervalue,
        doe: formik.values.doe || initialdoe,
        dom: formik.values.dom || initialdom,
        vendorName: formik.values.vendorName || initialname,
        vendorPhone: formik.values.vendorPhone || initialphone,
      };
      function demandToString(demand) {
        return [
          demand.stockid,
          demand.hospitalid,
          demand.productid,
          demand.batchno,
          demand.unitcost,
          demand.totalquantity,
          demand.gst,
          demand.grandtotal.
          demand.buffervalue,
          demand.doe,
          demand.dom,
          demand.vendorName,
          demand.vendorPhone
        ].join(", ");
      }
      console.log(demandToString(demand));
      // const request = {
      //   userid: localStorage.getItem("id"),
      //   hospitalid: localStorage.getItem("hospitalid"),
      //   inventorymanagerid: localStorage.getItem("inventorymanagerid"),
      //   productid: id,
      //   demand: demandToString(demand),
      //   status: "pending",
      //   requestdate: new Date().toLocaleDateString("en-GB", {
      //     day: "2-digit",
      //     month: "2-digit",
      //     year: "numeric",
      //   }),
      // };
      // try {
      //   setLoading(true);
      //   const postRequest = async () => {
      //     const response = await axios.post(
      //       `${process.env.REACT_APP_BASE_URL}postrequests`,
      //       request,
      //     );
      //     setLoading(false);
      //     console.log(response);
      //   };
      //   postRequest();
      // } catch (error) {
      //   setShowAlertDialog(true);
      //   setAlertText("Error Posting Request");
      //   // alert("Error Posting Request");
      //   console.error("Error creating request:", error);
      // }
      // setShowAlertDialog(true);
      // setAlertText("Your Request is submitted To HOH successfully");
    
      // setLoading(false);
      // setIsProductRegistered(true);
      // setOpen(true);
    } catch (error) {
      setShowAlertDialog(true);
      setAlertText("Error Registering Products");
      // alert("Error Registering Products");
      console.error("Error creating Products:", error);
      setLoading(false);
    }
  };

  

  

  useEffect(() => {
    const calculateGrandTotal = () => {
      const totalCost = formik.values.unitcost * formik.values.totalquantity;
      const gstAmount = (formik.values.gst / 100) * totalCost;
      const grandTotal = totalCost + gstAmount;
      setGrandTotal(grandTotal.toFixed(2));
    };

    calculateGrandTotal();
  }, [formik.values.unitcost, formik.values.totalquantity, formik.values.gst]);

  const formatDate = (date) => (date ? dayjs(date).format("DD/MM/YYYY") : "");

  return (
    <form onSubmit={formik.handleSubmit}>
      <AlertDialog
        onClose={() => setShowAlertDialog(false)}
        open={showAlertDialog}
        text={alertText}
      />
      <LoaderOverlay loading={loading} />
      <div>
        {isStockRegistered && (
          <PopupMessage message="Stock Registered Successfully" />
        )}
        {errorMessage && <PopupMessage message={errorMessage} />}
        <section
          className="p-5 w-100"
          style={{ backgroundColor: "#eeeee", borderRadius: ".5rem .5rem 0 0" }}
        >
          <div className="row">
            <div className="col-12">
              <div className="card text-black" style={{ borderRadius: "25px" }}>
                <div className="card-body p-md-3">
                  <div className="row">
                    <div className="col">
                      <p className="text-left h2 mb-3 mt-4">
                        Stock Information:
                      </p>

                     
                      {/* Other input fields */}
                      <div className="row mt-3">
                        <label htmlFor="firstname" className="form-label">
                          Product Name
                        </label>
                        <input
                          id="firstname"
                          name="firstname"
                          className="form-control"
                          placeholder={initialproductname}
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={true}
                        />
                      </div>
                      <div className="row mt-3">
                        <label htmlFor="firstname" className="form-label">
                          Product UPC
                        </label>
                        <input
                          id="firstname"
                          name="firstname"
                          className="form-control"
                          placeholder={initialupccode}
                          value={formik.values.upccode}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={true}
                        />
                      </div>

                      <div className="row mt-3">
                        <label htmlFor="firstname" className="form-label">
                          Manufacturer
                        </label>
                        <input
                          id="manufacturer"
                          name="manufacturer"
                          className="form-control"
                          value={formik.values.manufacturer}
                          placeholder={initialmanufacturer}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={true}
                        />
                      </div>
                      <div className="row mt-3">
                        <label htmlFor="email" className="form-label">
                          Product Type
                        </label>
                        <input
                          id="email"
                          name="email"
                          className="form-control"
                          value={formik.values.email}
                          placeholder={initialproducttype}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={true}
                        />
                      </div>
                      <div className="row mt-3">
                        <label htmlFor="address" className="form-label">
                          Product Category/Sub Category
                        </label>
                        <input
                          id="address"
                          name="address"
                          className="form-control"
                          placeholder={initialcategory}
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          type="text"
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col md-5 ">
                      <div className="image-upload-container">
                        <Box
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: 500,
                            overflow: "hidden", // Ensures the image does not extend beyond the container bounds
                          }}
                        >
                          {initialproductImage ? (
                            <img
                              src={initialproductImage}
                              alt="Product"
                              style={{
                                width: "100%", // Ensures the image fills the container
                                height: "100%",
                                objectFit: "cover", // Ensures the image covers the container without distortion
                              }}
                            />
                          ) : (
                            <img
                              width="96"
                              height="96"
                              src="http://img.icons8.com/color/96/add-image.png"
                              alt="add-image"
                              />
                            )}
                            {formik.values.productImage && (
                              <img
                                src={URL.createObjectURL(
                                  formik.values.productImage,
                                )}
                                alt="product-preview"
                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                              />
                            )}
                            
                        </Box>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="row">
                      <p className="text-left h2 mb-3 mt-4">Vendor Details</p>

                      <div className="row mt-3">
                        <div className="col text-left">
                          <label htmlFor="name" className="form-label" style={{ color: "red" }}>
                         Current Name - {initialname ? `(${initialname})` : "Not Set"}, Fill to Change

                          </label>
                          <input
                            id="name"
                            name="name"
                            className="form-control"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.name && formik.touched.name ? (
                            <small className="text-danger mt-1">
                              {formik.errors.name}
                            </small>
                          ) : null}
                        </div>
                        <div className="col text-left">
                        <label htmlFor="phone" className="form-label" style={{ color: "red" }}>
                        Current Phone No - {initialphone ? `(${initialphone})` : "Not Set"}, Fill to Change
                          </label>
                          <input
                            id="phone"
                            name="phone"
                            className="form-control"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            type="text"
                          />
                          {formik.errors.phone && formik.touched.phone ? (
                            <small className="text-danger mt-1">
                              {formik.errors.phone}
                            </small>
                          ) : null}
                        </div>
                      </div>

                      <p className="text-left h2 mb-3 mt-4">Stock Details</p>
                      <div className="row mt-3">
                        <div className="col text-left">
                        <label htmlFor="batchno" className="form-label" style={{ color: "red" }}>
                        Current Batch Code - {initialbatchno ? `(${initialbatchno})` : "Not Set"}, Fill to Change
                          </label>
                          <input
                            id="batchno"
                            name="batchno"
                            className="form-control"
                            value={formik.values.batchno}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.batchno && formik.touched.batchno ? (
                            <small className="text-danger mt-1">
                              {formik.errors.batchno}
                            </small>
                          ) : null}
                        </div>
                        <div className="col text-left">
                        <label htmlFor="unitcost" className="form-label" style={{ color: "red" }}>
                        Current Unit Cost Rs. - {initialunitcost ? `(${initialunitcost})` : "Not Set"}, Fill to Change
                          </label>
                          <input
                            id="unitcost"
                            name="unitcost"
                            className="form-control"
                            value={formik.values.unitcost}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            type="text"
                          />
                          {formik.errors.unitcost && formik.touched.unitcost ? (
                            <small className="text-danger mt-1">
                              {formik.errors.unitcost}
                            </small>
                          ) : null}
                        </div>
                        <div className="col text-left">
                        <label htmlFor="totalquatity" className="form-label" style={{ color: "red" }}>
                        Current Quantity - {initialtotalquantity ? `(${initialtotalquantity})` : "Not Set"}, Fill to Change
                          </label>
                          <input
                            id="totalquantity"
                            name="totalquantity"
                            className="form-control"
                            value={formik.values.totalquantity}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            type="text"
                          />
                          {formik.errors.totalquantity &&
                          formik.touched.totalquantity ? (
                            <small className="text-danger mt-1">
                              {formik.errors.totalquantity}
                            </small>
                          ) : null}
                        </div>
                        <div className="col text-left">
                          <label htmlFor="gst" className="form-label" style={{ color: "red" }}>
                          Current GST Rate - {initialgst ? `${initialgst}%` : "Not Set"}, Select to Change
                            </label>
                          <select
                            id="gst"
                            name="gst"
                            className="form-control"
                            value={formik.values.gst} // Bind Formik's value
                            onChange={formik.handleChange} // Handle change with Formik
                            onBlur={formik.handleBlur} // Handle blur with Formik
                          >
                            <option value="">Select GST Rate</option>
                            <option value="5">5%</option>
                            <option value="10">10%</option>
                            <option value="12">12%</option>
                            <option value="18">18%</option>
                            <option value="20">20%</option>

                            <option value="28">28%</option>
                          </select>
                          {formik.touched.gst && formik.errors.gst ? (
                            <small className="text-danger mt-1">
                              {formik.errors.gst}{" "}
                              {/* Show Formik's error message */}
                            </small>
                          ) : null}
                        </div>

                        <div className="col text-left">
                        <label htmlFor="grandtotal" className="form-label" style={{ color: "red" }}>
                        Current Total Amount Rs. - {initialgrandtotal ? `(${initialgrandtotal})` : "Not Set"}
                          </label>
                          <input
                            id="grandtotal"
                            name="grandtotal"
                            className="form-control"
                            placeholder={initialgrandtotal || grandtotal}
                            value={grandtotal}
                          
                            type="text"
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                       <div className="col text-center">
  <label className="form-label" style={{ color: "red" }}>
    Back Date {formik.values.backDate ? `(${formik.values.backDate})` : "(Not Set)"}
  </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Back date"
                              format="DD/MM/YYYY"
                              value={dayjs(
                                formik.values.backDate,
                                "DD-MM-YYYY",
                              )}
                              onChange={(newValue) => {
                                formik.setFieldValue(
                                  "backDate",
                                  newValue ? newValue.format("DD-MM-YYYY") : "",
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                        <div className="col text-center">
  <label className="form-label" style={{ color: "red" }}>
    Manufacture Date {initialdom ? `(${initialdom})` : "(Not Set)"}
  </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Date of Manufacturing*"
                              value={
                                formik.values.dom
                                  ? dayjs(formik.values.dom, "DD/MM/YYYY")
                                  : null
                              }
                              onChange={(newValue) => {
                                if (newValue && newValue.isAfter(dayjs())) {
                                  alert(
                                    "Invalid Date. Please select a date before the current date.",
                                  );
                                  formik.setFieldValue("dom", ""); // Reset if invalid
                                } else {
                                  formik.setFieldValue(
                                    "dom",
                                    newValue
                                      ? newValue.format("DD/MM/YYYY")
                                      : "",
                                  );
                                  formik.setFieldValue("doe", ""); // Reset DOE when DOM is selected
                                }
                              }}
                              onBlur={() => formik.setFieldTouched("dom", true)}
                              maxDate={dayjs()} // Disable dates after the current date
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                          {formik.touched.dom && formik.errors.dom ? (
                            <div className="mt-1">
                              <small className="text-danger">
                                {formik.errors.dom}
                              </small>
                            </div>
                          ) : null}
                        </div>
                        <div className="col text-center">
  <label className="form-label" style={{ color: "red" }}>
    Expiry Date {initialdoe ? `(${initialdoe})` : "(Not Set)"}
  </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label={
                                formik.values.type === "Equipments"
                                  ? "Date of PM*"
                                  : "Date of PM*"
                              }
                              value={
                                formik.values.doe
                                  ? dayjs(formik.values.doe, "DD/MM/YYYY")
                                  : null
                              }
                              onChange={(newValue) => {
                                if (
                                  formik.values.dom &&
                                  newValue &&
                                  newValue.isBefore(
                                    dayjs(formik.values.dom, "DD/MM/YYYY"),
                                  )
                                ) {
                                  alert(
                                    "Invalid Date. Please select a date after the Date of Manufacturing.",
                                  );
                                  formik.setFieldValue(
                                    "doe",
                                    formik.values.dom,
                                  ); // Set to DOM if invalid
                                } else {
                                  formik.setFieldValue(
                                    "doe",
                                    newValue
                                      ? newValue.format("DD/MM/YYYY")
                                      : "",
                                  );
                                }
                              }}
                              onBlur={() => formik.setFieldTouched("doe", true)}
                              minDate={
                                formik.values.dom
                                  ? dayjs(formik.values.dom, "DD/MM/YYYY")
                                  : undefined
                              } // Restrict to dates after DOM
                              disabled={!formik.values.dom} // Disable DOE field when DOM is not selected
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                          {formik.touched.doe && formik.errors.doe ? (
                            <div className="mt-1">
                              <small className="text-danger">
                                {formik.errors.doe}
                              </small>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="row mt-3 button-row">
                        <div className="d-flex justify-content-end">
                          <div className=" actionButtons">
                           
                          </div>
                          <div className="button-spacing"></div>{" "}
                          <div className="actionButtons">
                            {/* Add this div for spacing */}
                            <Button
                              variant="contained"
                              size="large"
                              onClick={() => {
                                console.log("Button Clicked");
                                handleSubmitAllProducts();
                              }}
                            >
                              Edit Stock
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </section>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Missing Information</DialogTitle>
          <DialogContent>
            <p>The following fields are missing:</p>
            <ul>
              {missingFields.map((field) => (
                <li key={field}>{field}</li>
              ))}
            </ul>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </form>
  );
};

export default StockEdit;
