import * as React from "react";
import {
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import Button from "@mui/material/Button";
import "./home.css";

import axios from "axios";

import { useState } from "react";
import DataTable, { TableFilterBtn } from "../../UI/DataTable";
import { GridRowEditStopReasons } from "@mui/x-data-grid";
import ExportBtn from "../../Admin/TotalHospital/ui/ExportBtn";
import LoaderOverlay from "../../Loader/LoaderOverlay.js";
function createData(
  _id,
  productid,
  name,
  type,
  batchno,
  manufacturer,
  category,
  unitcost,
  emergencytype,
  imname,
) {
  return {
    _id,
    productid,
    name,
    type,
    batchno,
    manufacturer,
    category,
    unitcost,
    emergencytype,
    imname,
  };
}

export default function StockOut({ hospitalid }) {
  const [rows, setRows] = useState([]);
  const [stocks, setStocks] = useState([]);
  const fulldate = new Date().toLocaleDateString("en-IN");
  let [loading, setLoading] = useState(false);

  const isImId = localStorage.getItem("inventorymanagerid") !== null;


  const getStockAndProductData = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}stocks/outvalue/details/hospital/${hospitalid}`;
      const { data } = await axios.get(url);
      setLoading(false);
      // Get the inventory manager ID from localStorage
      const inventoryManagerId = localStorage.getItem("inventorymanagerid");

      let stocksToSet;

      if (inventoryManagerId) {
        // If inventory manager ID exists, filter based on imid
        stocksToSet = data.filter((stock) => stock.imid === inventoryManagerId);
      } else {
        // If inventory manager ID is not present, use the original data
        stocksToSet = data;
      }

      setStocks(stocksToSet);

      // Create rows from stocks and set them in the state
      const newRows = await Promise.all(
        stocksToSet.map(async (stock) => {
          let a = createData(
            stock._id,
            stock.productDetails?._id || "",
            stock.productDetails?.name || "", // Add null check for productDetails.name
            stock.productDetails?.producttype || "", // Add null check for productDetails.producttype
            stock.batchno,
            stock.productDetails?.manufacturer || "", // Add null check for productDetails.manufacturer
            stock.productDetails?.category || "", // Add null check for productDetails.category
            stock.unitcost,
            stock.productDetails?.emergencytype || "", // Add null check for productDetails.emergencytyp

            inventoryManagerId ? "" : stock.inventoryManagerDetails.name, // Set to name if inventoryManagerId is null or empty
          );
          return a;
        }),
      );

      setRows(newRows);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getStockAndProductData();
  }, []);

  const [visibleColumns, setVisibleColumns] = useState({
    imname: true,
    name: true,
    manufacturer: true,
    category: true,
    emergencytype: true,
    type: true,
  });

  let tableColumns = columnDefinations;

  const columns = tableColumns
    .filter((col) => visibleColumns[col.field])
    .map((col) => ({
      ...col,
      headeralign: col.headeralign || "left",
      width: col.width || 150,
      align: col.align || "left",
      editable: col.editable !== undefined ? col.editable : true,
    }));

  const [rowModesModel, setRowModesModel] = useState({});
  const [count, setCount] = useState(0);
  const [columnAnchorEl, setColumnAnchorEl] = useState(null);

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row._id === newRow._id ? updatedRow : row)));
    return updatedRow;
  };

  const onRowsSelectionHandler = (id) => {
    const selectedIDs = new Set(id);
    const selectedRowsData = id.map((id) => rows.find((row) => row._id === id));
    setCount(selectedIDs);
  };

  const handleColumnClose = () => {
    setColumnAnchorEl(null);
  };

  const handleColumnClick = (event) => {
    setColumnAnchorEl(event.currentTarget);
  };

  const toggleColumnVisibility = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const selectedData = [];
  if (count !== 0 && count.size !== 0) {
    for (const entry of count.values()) {
      const row = rows.find((r) => r._id === entry);
      if (row) {
        const a = [];
        Object.keys(visibleColumns).forEach((key) => {
          if (visibleColumns[key]) {
            a.push(row[key]);
          }
        });

        selectedData.push(a);
      }
    }
  }

  const headerObj = {
    name: "Product",
    manufacturer: "Manufacturer",
    category: "Category",
    emergencytype: "Emergency type",
    type: "Type",
    imname: "Inventory Manager",
  };

  const headers = [];

  Object.keys(visibleColumns).forEach((key) => {
    if (visibleColumns[key]) {
      headers.push(headerObj[key]);
    }
  });


  const [colMenuValues, setColMenuValues] = useState({});

  const finalRows = generateRows(rows, colMenuValues);

  return (
    <main className="main-container">
      <LoaderOverlay loading={loading} />
      <div>
        <section
          className="p-5 w-100"
          style={{
            backgroundColor: "#eeeee",
            borderRadius: ".5rem .5rem 0 0",
          }}
        >
          <div className="row">
            <div className="col">
              <div className="card text-black" style={{ borderRadius: "25px" }}>
                <div className="card-body p-md-3">
                  <div className="card-body p-md-3">
                    <Typography
                      variant="h4"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "20px",
                        fontSize: "2.5rem",
                        fontWeight: "bold",
                        color: "black",
                        padding: "10px",
                        textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
                      }}
                    >
                      Stock Out
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="flex-end"
                    >
                      <TableFilterBtn
                        anchorEl={columnAnchorEl}
                        onClose={handleColumnClose}
                        onClick={handleColumnClick}
                        columnDefinitions={columnDefinations}
                        visibleColumns={visibleColumns}
                        onChange={toggleColumnVisibility}
                      />
                      <ExportBtn
                        rows={selectedData}
                        isSelected={true}
                        headers={headers}
                        fileName="Stockout_Report"
                      />
                    </Stack>
                    <DataTable
                      rows={finalRows}
                      columns={columns}
                      rowModesModel={rowModesModel}
                      onRowModesModelChange={handleRowModesModelChange}
                      onRowEditStop={handleRowEditStop}
                      processRowUpdate={processRowUpdate}
                      onRowsSelectionHandler={onRowsSelectionHandler}
                      whichPage="stockout"
                      colMenuValues={colMenuValues}
                      setColMenuValues={setColMenuValues}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

function generateRows(a, colValues) {
  if (!a || a.length === 0) return [];

  return a.filter((item) => {
    for (const key in colValues) {
      if (colValues[key] && colValues[key] !== "" && colValues[key] !== "All") {
        if (item[key] !== colValues[key]) {
          return false;
        }
      }
    }

    return true;
  });
}


const disableColumnMenu = true;
let width = 180;

const columnDefinations = [
  {
    field: "name",
    headerName: "PRODUCT",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "imname",
    headerName: "INVENTORY MANAGER",
    width: 200,
    align: "left",
    headerAlign: "left",
    disableColumnMenu,
  },
  {
    field: "type",
    headerName: "PPRODUCT TYPE",
    headerAlign: "left",
    width,
    align: "left",
    editable: true,
  },
  {
    field: "manufacturer",
    headerName: "MANUFACTURER",
    width,
    disableColumnMenu,
  },
  {
    field: "category",
    headerName: "CATEGORY",
    width,
  },
  // { field: "unitcost", headerName: "UNIT COST", width, editable: true },
  {
    field: "emergencytype",
    headerName: "EMERGENCY TYPE",
    width,
  },
];
