
import Axios from "axios";

export async function deleteProductsRequest(productIdSet, hospitalid) {
  const userid = localStorage.getItem("id");
  const inventorymanagerid = localStorage.getItem("inventorymanagerid");


  const requests = [...productIdSet].map(proId => {
    const requestObj = {
      userid,
      hospitalid,
      inventorymanagerid,
      productid: proId,
      demand: "delete",
      status: "pending",
      requestdate: new Date().toLocaleDateString("en-IN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
    };

    return Axios.post(`${process.env.REACT_APP_BASE_URL}postrequests`, requestObj);
  });

  try {
    const responses = await Promise.all(requests);
    console.log(responses);
  } catch (error) {
    console.error("Error in sending delete requests:", error);
  }
}
