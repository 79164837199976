import { TextField } from "@material-ui/core";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchBar({ value, onChange }) {
  return (
    <article>
      <TextField
        value={value}
        onChange={onChange}
        variant="outlined"
        placeholder="Search by name..."
        size="small"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </article>
  )
}
