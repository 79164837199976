import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import logo from "../assets/Semamart.png";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Stack from "@mui/material/Stack";
import axios from "axios";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FiDownload } from "react-icons/fi";
import AlertDialog from "../UI/AlertDialog";

import { GridRowModes } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";
import CalenderMenu from "../UI/CalenderMenu";
import DataTable, { TableFilterBtn } from "../UI/DataTable";
import MainScreenContainer from "../UI/MainScreenContainer";
import { totalProColDefination } from "./TotalProduct.ColDef";
import { deleteProductsRequest } from "./TotalProduct.Utils";
import SearchBar from "../UI/SearchBar";

const inventoryManagerId = localStorage.getItem("inventorymanagerid");

export default function TotalProductTable({ hospitalid, heading = "Total Products" }) {
  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [columnAnchorEl, setColumnAnchorEl] = React.useState(null);
  let [loading, setLoading] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const [alertDialog, setAlertDialog] = React.useState(false);
  const [alertText, setAlertText] = React.useState("");

  const [visibleColumns, setVisibleColumns] = React.useState({
    date: true,
    ...(inventoryManagerId !== null ? { actions: true } : { imname: true }),
    type: true,
    name: true,
    category: true,
    manufacturer: true,
    origin: true,
    subcat: true,
    emergencytype: true,
  });

  const getprod = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}productsdata/${hospitalid}`;
      const { data } = await axios.get(url);

      setLoading(false);
      let productsToSet;

      if (inventoryManagerId) {
        productsToSet = data.documents.filter(
          (product) => product.imid === inventoryManagerId,
        );
      } else {
        productsToSet = data.documents;
      }

      setRows(makeRows(productsToSet, handleEditClick, handleDeleteClick));
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getprod();
  }, []);

  const [rowModesModel, setRowModesModel] = React.useState({});
  const [count, setCount] = React.useState(0);

  function handleEditClick(id) {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    navigate(`/productedit`, { state: { id } });
  }

  function handleDeleteClick(id) {
    alert(
      "Are you sure you want to delete this product & all stocks and issueds related to it?",
    );
    const request = {
      userid: localStorage.getItem("id"),
      hospitalid: hospitalid,
      inventorymanagerid: localStorage.getItem("inventorymanagerid"),
      productid: id,
      demand: "delete",
      status: "pending",
      requestdate: new Date().toLocaleDateString("en-IN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
    };
    try {
      const postRequest = async () => {
        const response = await Axios.post(
          `${process.env.REACT_APP_BASE_URL}postrequests`,
          request,
        );

        console.log(response);
      };
      postRequest();
    } catch (error) {
      setAlertDialog(true);
      setAlertText("Error Posting Request");
      console.error("Error creating request:", error);
    }
    setAlertDialog(true);
    setAlertText("Your Request is submitted successfully");
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleColumnClick = (event) => {
    setColumnAnchorEl(event.currentTarget);
  };

  const handleColumnClose = () => setColumnAnchorEl(null);

  const onRowsSelectionHandler = (id) => {
    const selectedIDs = new Set(id);
    setCount(selectedIDs);
  };

  const headerObj = {
    date: "Date",
    imname: "Inventory Manager",
    name: "Name",
    producttype: "Type",
    category: "Category",
    manufacturer: "Manufacturer",
    origin: "Origin",
    subcategory: "Sub Category",
    emergencytype: "Emergency Type",
    actions: "Actions",
  };

  const selectedData = [];
  if (count !== 0 && count.size !== 0) {
    for (const entry of count.values()) {
      const row = rows.find((r) => r._id === entry);
      if (row) {
        const a = [];
        Object.keys(visibleColumns).forEach((key) => {
          if (visibleColumns[key]) {
            if (key === "imname") {
              a.push(row.imname);
            } else {
              a.push(row[key]);
            }
          }
        });

        selectedData.push(a);
      }
    }
  }

  const headers = [];
  Object.keys(visibleColumns).forEach((key) => {
    if (visibleColumns[key]) {
      headers.push(headerObj[key]);
    }
  });

  const handleCSVExport = () => {
    if (count.size !== 0) {
      const csvContent = [headers, ...selectedData]
        .map((e) => e.join(","))
        .join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `${new Date().toLocaleDateString()}_Total_Product.csv`,
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      setAlertDialog(true);
      setAlertText("Please Select The Rows To Generate CSV");
    }
  };

  const toggleColumnVisibility = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const handlePrint = () => {
    if (count.size !== 0) {
      const doc = new jsPDF({ compress: true, orientation: "landscape" });
      let currentY = 5;

      doc.addImage(logo, "PNG", 5, currentY, 0, 10);
      currentY += 25;

      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      doc.text("Total Product", 70, currentY);
      doc.setFontSize(12);

      currentY += 10;
      doc.autoTable({
        startY: currentY,
        head: [headers],
        body: selectedData,
        theme: "grid",
        headStyles: { fillColor: [22, 160, 133], textColor: 255, fontSize: 10 },
        bodyStyles: { fontSize: 9 },
        alternateRowStyles: { fillColor: [240, 240, 240] },
        styles: { cellPadding: 3 },
      });

      doc.setFontSize(10);
      doc.setFont("helvetica", "italic");
      doc.text("semamart.com", 14, doc.internal.pageSize.height - 10);
      doc.text("contact@semamart.com", 60, doc.internal.pageSize.height - 10);

      doc.save("ProductReport.pdf");
    } else {
      setAlertDialog(true);
      setAlertText("Please Select The Rows To Generate PDF");
    }
  };

  const columnDefinitions = totalProColDefination;

  const columns = columnDefinitions.filter((col) => visibleColumns[col.field]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);

  function resetDateHandler() {
    setStartDate("");
    setEndDate("");
  }

  const [colMenuValues, setColMenuValues] = useState({});

  useEffect(() => {
    const result = filterByDateRangeAndColMenu(
      rows,
      startDate,
      endDate,
      colMenuValues,
    );
    setFilteredRows(result);
  }, [startDate, endDate, rows, colMenuValues]);

  const [showBulkDeleteBtn, setShowBulkDeleteBtn] = useState(false);
  useEffect(() => {
    if (count !== 0 && count.size !== 0) {
      setShowBulkDeleteBtn(true);
    } else {
      setShowBulkDeleteBtn(false);
    }
  }, [count]);

  async function handleBulkDeleteProduct() {
    const deleteConfirmation = window.confirm("Do you want to delete these products ?");
    if (!deleteConfirmation) return;
    try {
      await deleteProductsRequest(count, hospitalid);
      setAlertDialog(true);
      setAlertText("Requests Sent to HOH");
      setCount(0);
    } catch (error) {
      console.log(error);
      setAlertDialog(true);
      setAlertText("Error Posting Request");
    }
  }

  const [searchText, setSearchText] = useState("");
  const searchedRows = filteredRows.filter((el) => {
    if (searchText === "") return true;
    return el.name.toLowerCase().includes(searchText.toLowerCase());
  });

  return (
    <MainScreenContainer heading={heading} loading={loading}>
      <AlertDialog
        onClose={() => setAlertDialog(false)}
        open={alertDialog}
        text={alertText}
      />
      <Box
        sx={{
          width: "90%",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          padding: "20px",
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={2} alignItems="center">
            <CalenderMenu
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              onReset={resetDateHandler}
            />
            <SearchBar value={searchText} onChange={(e) => setSearchText(e.target.value)} />
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            {inventoryManagerId !== null && showBulkDeleteBtn && (
              <Button
                style={{
                  backgroundColor: "#2E718A",
                  color: "#fff",
                }}
                variant="contained"
                onClick={handleBulkDeleteProduct}
              >
                Delete Products
              </Button>
            )}
            <TableFilterBtn
              anchorEl={columnAnchorEl}
              onClose={handleColumnClose}
              onClick={handleColumnClick}
              columnDefinitions={columnDefinitions}
              visibleColumns={visibleColumns}
              onChange={toggleColumnVisibility}
            />
            <Button
              style={{
                backgroundColor: "#2E718A",
                color: "#fff",
              }}
              variant="contained"
              startIcon={<FiDownload />}
              onClick={handleClick}
            >
              Export
            </Button>
            <Menu
              id="export-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "export-button",
              }}
            >
              <MenuItem onClick={handlePrint}>PDF</MenuItem>
              <MenuItem onClick={handleCSVExport}>CSV</MenuItem>
            </Menu>
          </Stack>
        </Stack>
        <DataTable
          rows={searchedRows}
          columns={columns}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowsSelectionHandler={onRowsSelectionHandler}
          whichPage="total"
          colMenuValues={colMenuValues}
          setColMenuValues={setColMenuValues}
        />
      </Box>
    </MainScreenContainer>
  );
}

function filterByDateRangeAndColMenu(rows, startDate, endDate, colValues) {
  const b = rows.filter((item) => {
    for (const key in colValues) {
      if (colValues[key] && colValues[key] !== "" && colValues[key] !== "All") {
        if (item[key] !== colValues[key]) {
          return false;
        }
      }
    }

    return true;
  });

  if (!startDate || !endDate) return b;

  const start = new Date(startDate).setHours(0, 0, 0, 0);
  const end = new Date(endDate).setHours(23, 59, 59, 999);

  return b.filter((row) => {
    const [day, month, year] = row.date.split("/");
    const rowDate = new Date(year, month - 1, day).getTime();

    return rowDate >= start && rowDate <= end;
  });
}

function makeRows(data, handleEditClick, handleDeleteClick) {
  if (!data || data.length === 0) return [];

  const rowsFromData = data.reverse().map((el) => {
    return {
      _id: el._id,
      date: el.date,
      name: el.name,
      imname: el.inventoryManager ? el.inventoryManager.name : "n/a",
      manufacturer: el.manufacturer,
      type: el.producttype?.name || "-", // Extract producttype name
      category: el.category?.name || "-", // Extract category name
      origin: el.origin,
      subcat: el.subcategory?.name || "-", // Extract subcategory name
      emergencytype: el.emergencytype,
      handleDeleteClick,
      handleEditClick,
    };
  });

  return rowsFromData;
}
