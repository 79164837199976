import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { IconButton, Stack } from "@mui/material";

const inventoryManagerId = localStorage.getItem("inventorymanagerid");
const isIManager = inventoryManagerId !== null;
const width = 200;
const disableColumnMenu = true;

export const columnDefinitions = [
  {
    field: "name",
    headerName: "PRODUCT NAME",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },

  {
    field: "type",
    headerName: "PRODUCT TYPE",
    headerAlign: "left",
    width,
    align: "left",
  },
  {
    field: "manufacturer",
    headerName: "MANUFACTURER",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "category",
    headerName: "CATEGORY",
    width,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "totalquantity",
    headerName: "TOTAL QUANTITY",
    width,
    align: "left",
    headerAlign: "left",
    disableColumnMenu,
  },
  {
    field: "emergencytype",
    headerName: "EMERGENCY TYPE",
    width,
    align: "left",
    headerAlign: "left",
  },
];

if (!isIManager) {
  columnDefinitions.splice(1, 0, {
    field: "imname",
    headerName: "INVENTORY MANAGER",
    width,
    disableColumnMenu,
    headerAlign: "left",
    align: "left",
  });
}
// else {
//   columnDefinitions.push({
//     field: "actions",
//     headerName: "ACTIONS",
//     width,
//     align: "left",
//     renderCell: (params) => (
//       <Stack direction="row" spacing={2}>
//         <IconButton
//           style={{
//             backgroundColor: "white",
//             color: "green",
//             transition: "background-color 0.3s, color 0.3s",
//           }}
//           size="small"
//           onClick={() => params.row.handleEditClick(params.row._id)} // Ensure handleEditClick exists
//           >
//           <EditIcon />
//         </IconButton>
//
//       </Stack>
//     ),
//   }
//   );
// }

