import { Stack } from "@mui/material";
import { useState } from "react";
import ExportBtn from "../Admin/TotalHospital/ui/ExportBtn";
import DataTable, { TableFilterBtn } from "../UI/DataTable";
import MainScreenContainer from "../UI/MainScreenContainer";
import SearchBar from "../UI/SearchBar";
import { vendorListColDef } from "./Vendor.ColDef";

export default function VendorTable(
  { loading, vendors, handleShowStock, handleShowProduct }
) {
  const [visibleColumns, setVisibleColumns] = useState({
    name: true,
    phone: true,
    address: true,
    district: true,
    state: true,
    gstno: true,
    actions: true
  });


  const columns = vendorListColDef.filter((col) => visibleColumns[col.field]);

  const [rowModesModel, setRowModesModel] = useState({});
  const [count, setCount] = useState(0);
  const [columnAnchorEl, setColumnAnchorEl] = useState(null);

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const onRowsSelectionHandler = (id) => {
    const selectedIDs = new Set(id);
    setCount(selectedIDs);
  };

  const handleColumnClose = () => {
    setColumnAnchorEl(null);
  };

  const handleColumnClick = (event) => {
    setColumnAnchorEl(event.currentTarget);
  };

  const toggleColumnVisibility = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };
  const [colMenuValues, setColMenuValues] = useState({});

  const rows = generateRows(vendors, handleShowStock, colMenuValues, handleShowProduct);

  const selectedData = [];
  if (count !== 0 && count.size !== 0) {
    for (const entry of count.values()) {
      const row = rows.find((r) => r._id === entry);
      if (row) {
        const a = [];
        Object.keys(visibleColumns).forEach((key) => {
          if (visibleColumns[key]) {
            a.push(row[key]);
          }
        });

        selectedData.push(a);
      }
    }
  }

  const headerOj = {
    name: "Vendor Name",
    phone: "Phone",
    address: "Address",
    district: "District",
    state: "State",
    gstno: "GST Number"
  };
  const headers = [];

  Object.keys(visibleColumns).forEach((key) => {
    if (visibleColumns[key]) {
      headers.push(headerOj[key]);
    }
  });

  const [searchText, setSearchText] = useState("")
  const searchedRows = rows.filter(el => {
    if (searchText === "") return true
    return el.name.toLowerCase().includes(searchText.toLowerCase())
  })

  return (
    <MainScreenContainer loading={loading} heading="Vendor List">
      <Stack direction="row" justifyContent="space-between">
        <SearchBar value={searchText} onChange={e => setSearchText(e.target.value)} />
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <TableFilterBtn
            anchorEl={columnAnchorEl}
            onClose={handleColumnClose}
            onClick={handleColumnClick}
            columnDefinitions={vendorListColDef}
            visibleColumns={visibleColumns}
            onChange={toggleColumnVisibility}
          />
          <ExportBtn
            rows={selectedData}
            isSelected={true}
            headers={headers}
            fileName="Vendor_list"
          />
        </Stack>
      </Stack>
      <DataTable
        rows={searchedRows}
        columns={columns}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowsSelectionHandler={onRowsSelectionHandler}
        whichPage="avail"
        colMenuValues={colMenuValues}
        setColMenuValues={setColMenuValues}
      />
    </MainScreenContainer>
  )
}

function generateRows(a, handleShowStock, colValues, handleShowProduct) {
  if (!a || a.length === 0) return [];

  const b = a.map((vendor) =>
    createData(
      vendor._id,
      vendor.hospitalid,
      vendor.name,
      vendor.phone,
      vendor.address,
      vendor.district,
      vendor.pin,
      vendor.state,
      vendor.gstno,
      vendor.status,
      vendor.created_at,
      vendor.updated_id,

      handleShowStock,
      handleShowProduct
    ),
  );

  return b.filter((item) => {
    for (const key in colValues) {
      if (colValues[key] && colValues[key] !== "" && colValues[key] !== "All") {
        if (item[key] !== colValues[key]) {
          return false;
        }
      }
    }

    return true;
  });
}

function createData(
  _id,
  hospitalid,
  name,
  phone,
  address,
  district,
  pin,
  state,
  gstno,
  status,
  created_at,
  updated_id,
  handleShowStock,
  handleShowProduct
) {
  return {
    _id,
    hospitalid,
    name,
    phone,
    address,
    district,
    pin,
    state,
    gstno,
    status,
    created_at,
    updated_id,
    handleShowStock,
    handleShowProduct
  };
}
