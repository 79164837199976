import { Stack, Typography } from "@mui/material";
import { GridRowEditStopReasons } from "@mui/x-data-grid";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import ExportBtn from "../Admin/TotalHospital/ui/ExportBtn";
import CalenderMenu from "../UI/CalenderMenu";
import DataTable, { TableFilterBtn } from "../UI/DataTable";
import LoaderOverlay from "../Loader/LoaderOverlay.js";
import SearchBar from "../UI/SearchBar.jsx";
// import { formatDateDelimiter } from "../Dashboard/Components/home";

export default function StockEntryTable() {
  const [rows, setRows] = useState([]);
  let [loading, setLoading] = useState(false);
  const gethistory = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}v2/history/stock-entry-details/${hospitalid}`;
      const { data } = await axios.get(url);
      setLoading(false);
      const newRows = data.map((stock) => {
        return {
          _id: stock._id,
          quantity: stock.quantity,
          name: stock.productid?.name,
          category: stock.productid?.category?.name || "n/a",
          manufacturer: stock.productid?.manufacturer,
          emergencytype: stock.productid?.emergencytype,
          department: stock.productid?.producttype?.name || "n/a",
          subdepartment: stock.productid?.subcategory?.name || "n/a",
          date: stock.date,
          productid: stock._id,
          imname: stock.imid?.name || "n/a"
        };
      });

      setRows(newRows);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    gethistory();
  }, []);

  const hospitalid = localStorage.getItem("hospitalid");
  const [visibleColumns, setVisibleColumns] = useState({
    imname: true,
    name: true,
    quantity: true,
    // productname: true,
    category: true,
    manufacturer: true,
    emergencytype: true,
    date: true,
    department: true,
    subdepartment: true,
  });

  const disableColumnMenu = true
  const columnDefinations = [
    { field: "date", headerName: "DATE", width: 120, disableColumnMenu },
    { field: "imname", headerName: "INVENTORY MANAGER", width: 190, disableColumnMenu },
    { field: "name", headerName: "PRODUCT NAME", width: 150, disableColumnMenu },
    { field: "department", headerName: "SCOPE", width: 150, },
    { field: "subdepartment", headerName: "DEPARTMENT", width: 210, disableColumnMenu },
    { field: "quantity", headerName: "QUANTITY", width: 150, disableColumnMenu },
    { field: "category", headerName: "CATEGORY", width: 150, },
    { field: "manufacturer", headerName: "MANUFACTURER", width: 150, disableColumnMenu },
    { field: "emergencytype", headerName: "EMERGENCY TYPE", width: 150, },
  ];

  const columns = columnDefinations
    .filter((col) => visibleColumns[col.field])
    .map((col) => ({
      ...col,
      headeralign: col.headeralign || "left",
    }));

  const [rowModesModel, setRowModesModel] = useState({});
  const [count, setCount] = useState(0);
  const [columnAnchorEl, setColumnAnchorEl] = useState(null);

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row._id === newRow._id ? updatedRow : row)));
    return updatedRow;
  };

  const onRowsSelectionHandler = (id) => {
    const selectedIDs = new Set(id);
    const selectedRowsData = id.map((id) => rows.find((row) => row._id === id));
    setCount(selectedIDs);
  };

  const handleColumnClose = () => {
    setColumnAnchorEl(null);
  };

  const handleColumnClick = (event) => {
    setColumnAnchorEl(event.currentTarget);
  };

  const toggleColumnVisibility = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const selectedData = [];
  if (count !== 0 && count.size !== 0) {
    for (const entry of count.values()) {
      const row = rows.find((r) => r._id === entry);
      if (row) {
        const a = [];
        Object.keys(visibleColumns).forEach((key) => {
          if (visibleColumns[key]) {
            a.push(row[key]);
          }
        });

        selectedData.push(a);
      }
    }
  }

  const headerObj = {
    date: "Date",
    imname: "Inventory manager",
    name: "Name",
    quantity: "Quantity",
    category: "Category",
    manufacturer: "Manufacturer",
    emergencytype: "Emergency Type",
    department: "Scope",
    subdepartment: "Department",
  };

  const headers = [];

  Object.keys(visibleColumns).forEach((key) => {
    if (visibleColumns[key]) {
      headers.push(headerObj[key]);
    }
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);


  function resetDateHandler() {
    setStartDate("");
    setEndDate("");
  }

  const [colMenuValues, setColMenuValues] = useState({});

  useEffect(() => {
    const result = filterByDateRangeAndColMenu(rows,
      startDate,
      endDate,
      colMenuValues
    );
    setFilteredRows(result);
  }, [startDate, endDate, rows, colMenuValues]);

  const [searchText, setSearchText] = useState("")
  const searchedRows = filteredRows.filter(el => {
    if (searchText === "") return true
    return el.name.toLowerCase().includes(searchText.toLowerCase())
  })

  return (
    <main className="main-container">
      <LoaderOverlay loading={loading} />
      <div>
        <section
          className="p-5 w-100"
          style={{
            backgroundColor: "#eeeee",
            borderRadius: ".5rem .5rem 0 0",
          }}
        >
          <div className="row">
            <div className="col">
              <div className="card text-black" style={{ borderRadius: "25px" }}>
                <div className="card-body p-md-3">
                  <Typography
                    variant="h4"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "20px",
                      fontSize: "2.5rem",
                      fontWeight: "bold",
                      color: "black",
                      padding: "10px",
                      textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
                    }}
                  >
                    Stock Entry
                  </Typography>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" gap={2} alignItems="center">
                      <CalenderMenu
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        onReset={resetDateHandler}
                      />
                      <SearchBar value={searchText} onChange={e => setSearchText(e.target.value)} />
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="flex-end"
                    >
                      <TableFilterBtn
                        anchorEl={columnAnchorEl}
                        onClose={handleColumnClose}
                        onClick={handleColumnClick}
                        columnDefinitions={columnDefinations}
                        visibleColumns={visibleColumns}
                        onChange={toggleColumnVisibility}
                      />
                      <ExportBtn
                        rows={selectedData}
                        isSelected={true}
                        headers={headers}
                        fileName="Stock_Entry_Report"
                      />
                    </Stack>
                  </Stack>
                  <DataTable
                    rows={searchedRows}
                    columns={columns}
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    onRowsSelectionHandler={onRowsSelectionHandler}
                    whichPage="entry"
                    colMenuValues={colMenuValues}
                    setColMenuValues={setColMenuValues}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

function filterByDateRangeAndColMenu(rows, startDate, endDate, colValues) {
  const b = rows.filter((item) => {
    for (const key in colValues) {
      if (colValues[key] && colValues[key] !== "" && colValues[key] !== "All") {
        if (item[key] !== colValues[key]) {
          return false;
        }
      }
    }

    return true;
  });

  if (!startDate || !endDate) return b;

  const start = new Date(startDate).setHours(0, 0, 0, 0);
  const end = new Date(endDate).setHours(23, 59, 59, 999);

  return b.filter((row) => {
    const [day, month, year] = row.date.split("/");
    const rowDate = new Date(year, month - 1, day).getTime();

    return rowDate >= start && rowDate <= end;
  });
}

