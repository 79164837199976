import ImReportTable from "./ImReportTable"

export default function ImReports({ reportType }) {
  const hospitalId = localStorage.getItem("hospitalid")
  const imId = localStorage.getItem("inventorymanagerid")

  let apiEndpoint
  let heading
  let page

  if (reportType === "entry") {
    apiEndpoint = "v2/history/stock-entry/" + hospitalId + "/" + imId
    heading = "Stock Entry"
    page = "entry"
  } else if (reportType === "issue") {
    apiEndpoint = "v2/history/stock-issue/" + hospitalId + "/" + imId
    heading = "Stock Issue"
    page = "issue"
  }

  return <ImReportTable api={apiEndpoint} heading={heading} page={page} />
}
