import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import Axios from "axios";
import axios from "axios";
import LoaderOverlay from "../../Loader/LoaderOverlay.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import NoteIcon from '@mui/icons-material/Note';
import "./UserRegistration.css";

function createData(orderId, fullOrderId, dateTime, hospitalName, productName, quantity, status, productDetails, hospitalDetails) {
  return { orderId, fullOrderId, dateTime, hospitalName, productName, quantity, status, productDetails, hospitalDetails };
}

function OrderRequest({ openSidebarToggle, OpenSidebar }) {
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);

  const [orders, setOrders] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [open, setOpen] = useState(false);
  const [hospitalDialogOpen, setHospitalDialogOpen] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [newStatus, setNewStatus] = useState("");
  const [historyDetails, setHistoryDetails] = useState([]);

  const getOrders = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}allorders`;
      const { data } = await axios.get(url);
      setLoading(false);
      console.log("Orders data:", data.orders);

      const formattedOrders = data.orders.map((order) => {
        console.log("Order:", order);
        return createData(
          order._id.slice(-5),
          order._id,
          `${order.date} ${order.time}`,
          order.hospitaldetails.hospitalname,
          order.productdetails ? order.productdetails.name : "N/A",
          order.quantity,
          order.status,
          order.productdetails || {},
          order.hospitaldetails || {}
        );
      });

      setOrders(formattedOrders);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  const handleClickOpen = (productDetails) => {
    setSelectedProduct(productDetails);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProduct(null);
  };

  const handleHospitalDialogOpen = (hospitalDetails) => {
    setSelectedHospital(hospitalDetails);
    setHospitalDialogOpen(true);
  };

  const handleHospitalDialogClose = () => {
    setHospitalDialogOpen(false);
    setSelectedHospital(null);
  };

  const handleStatusDialogOpen = (orderId) => {
    setSelectedOrderId(orderId);
    setStatusDialogOpen(true);
  };

  const handleStatusDialogClose = () => {
    setStatusDialogOpen(false);
    setSelectedOrderId(null);
    setNewStatus("");
  };

  const handleHistoryDialogOpen = async (orderId) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}orderhistory/${orderId}`;
      const { data } = await axios.get(url);
      setLoading(false);
      setHistoryDetails(data.history);
      setHistoryDialogOpen(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleHistoryDialogClose = () => {
    setHistoryDialogOpen(false);
    setHistoryDetails([]);
  };

  const handleStatusChange = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}updateorderstatus/${selectedOrderId}`;
      await axios.post(url, { status: newStatus });
  
      console.log("Selected Order ID:", selectedOrderId);
      console.log("Orders:", orders);
  
      const order = orders.find(order => order.fullOrderId === selectedOrderId);
      if (!order) {
        console.error("Order not found:", selectedOrderId);
        setLoading(false);
        return;
      }
  
      console.log("Found Order:", order);
      console.log("Order Product Details:", order.productDetails);
  
      if (!order.productDetails || !order.productDetails._id) {
        console.error("Product details not found for order:", selectedOrderId);
        setLoading(false);
        return;
      }
  
      const history = {
        hospitalid: order.productDetails.hospitalid,
        date: new Date().toLocaleDateString("en-IN"),
        productid: order.productDetails._id,
        quantity: Number(order.quantity),
        type: `Order-${newStatus}`,
        remark: selectedOrderId,
        imid: "None",
      };
  
      const historyresponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}posthistory`,
        history,
      );
      console.log("History posted successfully: ", historyresponse.data);
  
      setLoading(false);
      handleStatusDialogClose();
      console.log("Reloading window...");
      window.location.reload(); // Refresh the window
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div>
      <LoaderOverlay loading={loading} />
      <section className="p-5 w-100">
        <div className="row">
          <div className="col-12">
            <div className="card-body p-md-50">
              <div className="row justify-content-center">
                <div>
                  <TableContainer component={Paper} className="table">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              color: "#2e718a",
                              textTransform: "uppercase",
                              fontSize: "0.9rem",
                              padding: "10px",
                            }}
                          >
                            Order ID
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              color: "#2e718a",
                              textTransform: "uppercase",
                              fontSize: "0.9rem",
                              padding: "10px",
                            }}
                          >
                            Date & Time
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              color: "#2e718a",
                              textTransform: "uppercase",
                              fontSize: "0.9rem",
                              padding: "10px",
                            }}
                          >
                            Hospital Name
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              color: "#2e718a",
                              textTransform: "uppercase",
                              fontSize: "0.9rem",
                              padding: "10px",
                            }}
                          >
                            Product Name
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              color: "#2e718a",
                              textTransform: "uppercase",
                              fontSize: "0.9rem",
                              padding: "10px",
                            }}
                          >
                            Quantity
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              color: "#2e718a",
                              textTransform: "uppercase",
                              fontSize: "0.9rem",
                              padding: "10px",
                            }}
                          >
                            Status
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              color: "#2e718a",
                              textTransform: "uppercase",
                              fontSize: "0.9rem",
                              padding: "10px",
                            }}
                          >
                            Actions
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              color: "#2e718a",
                              textTransform: "uppercase",
                              fontSize: "0.9rem",
                              padding: "10px",
                            }}
                          >
                            History
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orders.map((row) => (
                          <TableRow
                            key={row.fullOrderId}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="center" component="th" scope="row">
                              {row.orderId}
                            </TableCell>
                            <TableCell align="center">{row.dateTime}</TableCell>
                            <TableCell
                              align="center"
                              style={{ cursor: "pointer", color: "#2e718a" }}
                              onClick={() => handleHospitalDialogOpen(row.hospitalDetails)}
                            >
                              {row.hospitalName}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ cursor: "pointer", color: "#2e718a" }}
                              onClick={() => handleClickOpen(row.productDetails)}
                            >
                              {row.productName}
                            </TableCell>
                            <TableCell align="center">{row.quantity}</TableCell>
                            <TableCell align="center">{row.status}</TableCell>
                            <TableCell align="center">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleStatusDialogOpen(row.fullOrderId)}
                              >
                                Change Status
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <NoteIcon
                                style={{ cursor: "pointer", color: "#2e718a" }}
                                onClick={() => handleHistoryDialogOpen(row.fullOrderId)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Product Details</DialogTitle>
                    <DialogContent>
                      {selectedProduct && (
                        <DialogContentText>
                          <strong>Name:</strong> {selectedProduct.name} <br />
                          <strong>Category:</strong> {selectedProduct.category} <br />
                          <strong>Subcategory:</strong> {selectedProduct.subcategory} <br />
                          <strong>UPC Code:</strong> {selectedProduct.upccode} <br />
                          <strong>Manufacturer:</strong> {selectedProduct.manufacturer} <br />
                          <strong>Origin:</strong> {selectedProduct.origin} <br />
                          <strong>Emergency Type:</strong> {selectedProduct.emergencytype} <br />
                          <strong>Description:</strong> {selectedProduct.description} <br />
                        </DialogContentText>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog open={hospitalDialogOpen} onClose={handleHospitalDialogClose}>
                    <DialogTitle>Hospital Details</DialogTitle>
                    <DialogContent>
                      {selectedHospital && (
                        <DialogContentText>
                          <strong>Name:</strong> {selectedHospital.hospitalname} <br />
                          <strong>Address:</strong> {selectedHospital.address} <br />
                          <strong>City:</strong> {selectedHospital.district} <br />
                          <strong>State:</strong> {selectedHospital.state} <br />
                          <strong>Zip Code:</strong> {selectedHospital.pincode} <br />
                          <strong>Contact:</strong> {selectedHospital.phone} <br />
                          <strong>Email:</strong> {selectedHospital.email} <br />
                        </DialogContentText>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleHospitalDialogClose} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog open={statusDialogOpen} onClose={handleStatusDialogClose}>
                    <DialogTitle>Change Order Status</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Select the new status for the order:
                      </DialogContentText>
                      <select
                        value={newStatus}
                        onChange={(e) => setNewStatus(e.target.value)}
                      >
                        <option value="">Select Status</option>
                        <option value="Processing">Processing</option>
                        <option value="Shipped">Shipped</option>
                        <option value="Delivered">Delivered</option>
                        <option value="Completed">Completed</option>
                      </select>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleStatusChange} color="primary">
                        OK
                      </Button>
                      <Button onClick={handleStatusDialogClose} color="secondary">
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog open={historyDialogOpen} onClose={handleHistoryDialogClose}>
                    <DialogTitle>Order History</DialogTitle>
                    <DialogContent>
                      {historyDetails.length > 0 ? (
                        historyDetails.map((history, index) => (
                          <DialogContentText key={index}>
                            <strong>{history.date}:</strong> {history.type} <br />
                          </DialogContentText>
                        ))
                      ) : (
                        <DialogContentText>No history available for this order.</DialogContentText>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleHistoryDialogClose} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OrderRequest;