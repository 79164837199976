import * as React from "react";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

// import Button from "@mui/material/Button";
import "./home.css";

import axios from "axios";
import {
  GridRowModes,
  GridToolbarContainer,
} from "@mui/x-data-grid";

import { useState } from "react";
import DataTable, { TableFilterBtn } from "../UI/DataTable";
import { columnDefinitions } from "./columnDefinations";
import { GridRowEditStopReasons } from "@mui/x-data-grid";
import ExportBtn from "../Admin/TotalHospital/ui/ExportBtn";
import MainScreenContainer from "../UI/MainScreenContainer";
import SearchBar from "../UI/SearchBar";

const inventoryManagerId = localStorage.getItem("inventorymanagerid");

export default function AvailaibleProductTable({ hospitalid }) {
  const [stocks, setStocks] = useState([]);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  let [loading, setLoading] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState({
    ...(localStorage.getItem("inventorymanagerid") === null
      && { imname: true }
      // : {actions: true}
    ),
    name: true,
    manufacturer: true,
    category: true,
    totalquantity: true,
    emergencytype: true,
    type: true,
  });

  const getStockAndProductData = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}aggregatedstocks/${hospitalid}`;
      const { data } = await axios.get(url);

      let stocksToSet;
      if (inventoryManagerId) {
        stocksToSet = data.documents.filter(
          (stock) => stock.inventoryManagerDetails._id === inventoryManagerId,
        );
      } else {
        stocksToSet = data.documents;
      }

      // Helper function to sum quantities (as strings)
      const sumQuantities = (stocks) => {
        return stocks
          .reduce((total, stock) => {
            const quantity = parseFloat(stock.totalquantity) || 0; // Convert quantity to a number
            return total + quantity;
          }, 0)
          .toString(); // Return the sum as a string
      };

      // Group stocks by productid and merge their quantities
      const mergedStocks = [];
      const stockMap = {};

      stocksToSet.forEach((stock) => {
        const productId = stock.productid;
        if (stockMap[productId]) {
          // If the productid already exists, sum the quantities
          // stockMap[productId].totalquantity = sumQuantities([
          //   stockMap[productId],
          //   stock,
          // ]);

          stockMap[productId].totalquantity = parseFloat(stockMap[productId].totalquantity) + parseFloat(stock.totalquantity)
        } else {
          // If the productid is new, add it to the map
          stockMap[productId] = { ...stock };
        }
      });

      // Convert the stockMap back to an array of merged stocks
      for (const key in stockMap) {
        mergedStocks.push(stockMap[key]);
      }


      // Set the merged stocks
      setStocks(mergedStocks);
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getStockAndProductData();
  }, []);

  const columns = columnDefinitions.filter((col) => visibleColumns[col.field]);

  const [rowModesModel, setRowModesModel] = useState({});
  const [count, setCount] = useState(0);
  const [columnAnchorEl, setColumnAnchorEl] = useState(null);

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const onRowsSelectionHandler = (id) => {
    const selectedIDs = new Set(id);
    setCount(selectedIDs);
  };
  function handleEditClick(id) {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });

    navigate(`/editstock`, { state: { stockid: id } });
  }

  const handleColumnClose = () => {
    setColumnAnchorEl(null);
  };

  const handleColumnClick = (event) => {
    setColumnAnchorEl(event.currentTarget);
  };

  const toggleColumnVisibility = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };
  const [colMenuValues, setColMenuValues] = useState({});

  const rows = generateRows(stocks, handleEditClick, colMenuValues);

  const selectedData = [];
  if (count !== 0 && count.size !== 0) {
    for (const entry of count.values()) {
      const row = rows.find((r) => r._id === entry);
      if (row) {
        const a = [];
        Object.keys(visibleColumns).forEach((key) => {
          if (visibleColumns[key]) {
            a.push(row[key]);
          }
        });

        selectedData.push(a);
      }
    }
  }

  const headerOj = {
    name: "Products name",
    imname: "Inventory Manager",
    manufacturer: "Manufacturer",
    type: "Type",
    category: "Category",
    totalquantity: "Quantity",
    emergencytype: "Emergency Type",
    actions: "Actions",

  };
  const headers = [];

  Object.keys(visibleColumns).forEach((key) => {
    if (visibleColumns[key]) {
      headers.push(headerOj[key]);
    }
  });

  const [searchText, setSearchText] = useState("")
  const searchedRows = rows.filter(el => {
    if (searchText === "") return true
    return el.name.toLowerCase().includes(searchText.toLowerCase())
  })

  return (
    <MainScreenContainer loading={loading} heading="Available Products">
      <Stack direction="row" justifyContent="space-between">
        <SearchBar value={searchText} onChange={e => setSearchText(e.target.value)} />
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <TableFilterBtn
            anchorEl={columnAnchorEl}
            onClose={handleColumnClose}
            onClick={handleColumnClick}
            columnDefinitions={columnDefinitions}
            visibleColumns={visibleColumns}
            onChange={toggleColumnVisibility}
          />
          <ExportBtn
            rows={selectedData}
            isSelected={true}
            headers={headers}
            fileName="Available_product"
          />
        </Stack>
      </Stack>
      <DataTable
        rows={searchedRows}
        columns={columns}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowsSelectionHandler={onRowsSelectionHandler}
        whichPage="avail"
        colMenuValues={colMenuValues}
        setColMenuValues={setColMenuValues}
      />
    </MainScreenContainer>
  );
}

function generateRows(a, handleEditClick, colValues) {
  if (!a || a.length === 0) return [];

  const b = a.map((stock) =>
    createData(
      stock._id,
      stock.productDetails?.name || "-", // Product Name
      stock.productTypeDetails?.name || "-", // Product Type
      stock.batchno || "-", // Batch Number
      stock.productDetails?.manufacturer || "-", // Manufacturer
      `${stock.categoryDetails?.name || "-"} / ${stock.subcategoryDetails?.name || "-"}`, // Category / Subcategory
      stock.unitcost || "0", // Unit Cost
      stock.totalquantity || "0", // Total Quantity
      stock.gst || "0", // GST
      stock.grandtotal || "0", // Grand Total
      stock.productDetails?.emergencytype || "-", // Emergency Type
      stock.productid || "-", // Product ID
      inventoryManagerId ? "" : stock.inventoryManagerDetails?.name || "-", // Inventory Manager Name
      handleEditClick,
    ),
  );

  return b.filter((item) => {
    for (const key in colValues) {
      if (colValues[key] && colValues[key] !== "" && colValues[key] !== "All") {
        if (item[key] !== colValues[key]) {
          return false;
        }
      }
    }

    return true;
  });
}

function createData(
  _id,
  name,
  type,
  batchno,
  manufacturer,
  category,
  unitcost,
  totalquantity,
  gst,
  grandtotal,
  emergencytype,
  productid,
  imname,
  handleEditClick,
) {
  return {
    _id,
    name,
    type,
    batchno,
    manufacturer,
    category,
    unitcost,
    totalquantity,
    gst,
    grandtotal,
    emergencytype,
    productid,
    imname,
    handleEditClick,
  };
}