import { useNavigate, useParams } from "react-router-dom"
import MinorHospital from "../TotalHospital/MinorHospital"
import { Box, IconButton, Stack } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close";

export default function AdminHospitalDetailsScreen() {
  const { hospitalId } = useParams()

  const navigate = useNavigate()

  return (
    <Stack display="grid" gridTemplateColumns={"300px 1fr"}>
      <Box bgcolor={"red"}>
        Navbar
      </Box>
      <Box padding={10}>
        <Stack justifyContent="space-between" flexDirection="row" alignItems="center">
          <h3 style={{ marginLeft: "10%", marginBottom: 0 }}>Hospital Details</h3>
          <IconButton onClick={() => { navigate("/totalhospital") }}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Stack>
        <MinorHospital hospitalId={hospitalId} />
      </Box>
    </Stack>
  )
}
