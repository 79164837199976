import { getSecondsInDay } from "@mui/x-date-pickers/internals/utils/time-utils";

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  return date.toLocaleDateString("en-IN");
};

export const generateLastCycleRows = (
  productNames,
  totalhistory,
  createEmptyRow,
) => {
  const rows = [];
  if (!productNames || productNames.lenght === 0) return;
  if (!totalhistory) return;

  // Iterate through each product's totalhistory
  productNames.forEach((product) => {
    const history = totalhistory.filter(
      (entry) => entry.productid === product._id,
    );
    if (history.length === 0) return; // Skip products with no history

    let currentRow = createEmptyRow(); // Start with an empty row for the current product
    const stockEntries = history.filter(
      (entry) => entry.type === "Stock Entry",
    ); // All Stock Entries (already sorted)

    if (stockEntries.length === 0) return; // If there are no stock entries, skip this product

    const latestStockEntry = stockEntries[stockEntries.length - 1]; // Last Stock Entry
    const secondLastStockEntry = stockEntries[stockEntries.length - 2]; // Second-last Stock Entry (if it exists)
    const lastHistoryEntry = history[history.length - 1]; // Last entry in history

    const startIndex = history.length - 7;
    const endIndex = history.length - 2;
    const hasStockEntry = history
      .slice(startIndex, endIndex + 1)
      .some((entry) => entry.type === "Stock Entry");

    // Case 1: If the last history entry is a "Stock Entry"
    if (lastHistoryEntry.type === "Stock Entry" && stockEntries.length > 1) {
      if (history[history.length - 7] && hasStockEntry) {
        currentRow.stockEntry.quantity = latestStockEntry.quantity;
        currentRow.stockEntry.date = formatDate(latestStockEntry.date);
      } else {
        currentRow.stockEntry.date = formatDate(secondLastStockEntry.date);
        currentRow.stockEntry.quantity = secondLastStockEntry.quantity;
        currentRow.stockOrderPlaced.date = formatDate(latestStockEntry.date);
        currentRow.stockOrderPlaced.quantity = latestStockEntry.quantity;

        // Look for any events between second-last and last stock entries
        for (
          let i = history.indexOf(secondLastStockEntry) + 1;
          i < history.indexOf(latestStockEntry);
          i++
        ) {
          const event = history[i];
          if (event.type === "Buffer Stock") {
            currentRow.bufferStock.date = formatDate(event.date);
            currentRow.bufferStock.quantity = event.quantity;
          } else if (event.type === "Stock Out") {
            currentRow.stockOut.date = formatDate(event.date);
            // currentRow.stockOut.quantity = event.quantity;
          } else if (event.type === "Order") {
            currentRow.ordered.date = formatDate(event.date);
            currentRow.ordered.quantity = event.quantity;
          }
        }
      }

      // Case 2: If the last history entry is not a "Stock Entry"
    } else {
      currentRow.stockEntry.date = formatDate(latestStockEntry.date);
      currentRow.stockEntry.quantity = latestStockEntry.quantity;

      // Look for any events after the last stock entry
      for (
        let i = history.indexOf(latestStockEntry) + 1;
        i < history.length;
        i++
      ) {
        const event = history[i];
        if (event.type === "Buffer Stock") {
          currentRow.bufferStock.date = formatDate(event.date);
          currentRow.bufferStock.quantity = event.quantity;
        } else if (event.type === "Stock Out") {
          currentRow.stockOut.date = formatDate(event.date);
        } else if (event.type === "Order") {
          currentRow.ordered.date = formatDate(event.date);
          currentRow.ordered.quantity = event.quantity;
        }
      }
    }
    // Push the row for this product
    rows.push({
      productName: product.name,
      ...currentRow,
    });
  });

  return rows;
};
