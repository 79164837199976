import { useEffect, useState } from "react";
import ImReportContainer from "./ImReportContainer";
import axios from "axios";
import { GridRowEditStopReasons } from "@mui/x-data-grid";
import MainScreenContainer from "../UI/MainScreenContainer";
import DataTable, { TableFilterBtn } from "../UI/DataTable";
import { Stack } from "@mui/material";
import CalenderMenu from "../UI/CalenderMenu";
import ExportBtn from "../Admin/TotalHospital/ui/ExportBtn";
import { ApiErrorTableMessage } from "./ImReport.UI";
import SearchBar from "../UI/SearchBar";

export default function ImReportTable({ api, heading, page }) {
  const [apiStatus, setApiStatus] = useState("idle")
  const [stockEntryHistory, setStockEntryHistory] = useState([])

  useEffect(() => {
    setSearchText("")
    async function getStockEntryHistory() {
      try {
        setApiStatus("loading")
        const url = process.env.REACT_APP_BASE_URL + api
        const res = await axios.get(url)
        if (res.statusText !== "OK") throw new Error("Something went wrong")
        setStockEntryHistory(res.data)
        setApiStatus("success")
      } catch (err) {
        setApiStatus("error")
        console.log(err)
      }
    }
    getStockEntryHistory()
  }, [api])

  console.log(stockEntryHistory)

  let rows = []
  if (apiStatus === "success") {
    rows = stockEntryHistory.map(el => ({
      _id: el._id,
      name: el.productid?.name || "-",
      quantity: el.quantity,
      category: el.productid?.category?.name || "-",
      manufacturer: el.productid?.manufacturer || "-",
      emergencytype: el.productid?.emergencytype || "-",
      department: el.productid?.producttype?.name || "-",
      subdepartment: el.productid?.subcategory?.name || "-",
      date: el.date,
      productid: el.productid?._id || "",
    }))
  }

  const [visibleColumns, setVisibleColumns] = useState(visibleColumnsInitial);

  const columns = columnDefinations
    .filter((col) => visibleColumns[col.field])
    .map((col) => ({
      ...col,
      editable: col.editable !== undefined ? col.editable : true,
    }));

  const [rowModesModel, setRowModesModel] = useState({});
  const [count, setCount] = useState(0);
  const [columnAnchorEl, setColumnAnchorEl] = useState(null);

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const onRowsSelectionHandler = (id) => {
    const selectedIDs = new Set(id);
    setCount(selectedIDs);
  };

  const handleColumnClose = () => {
    setColumnAnchorEl(null);
  };

  const handleColumnClick = (event) => {
    setColumnAnchorEl(event.currentTarget);
  };

  const toggleColumnVisibility = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const selectedData = [];
  if (count !== 0 && count.size !== 0) {
    for (const entry of count.values()) {
      const row = rows.find((r) => r._id === entry);
      if (row) {
        const a = [];
        Object.keys(visibleColumns).forEach((key) => {
          if (visibleColumns[key]) {
            a.push(row[key]);
          }
        });

        selectedData.push(a);
      }
    }
  }

  const headerObj = {
    date: "Date",
    name: "Name",
    quantity: "Quantity",
    category: "Category",
    manufacturer: "Manufacturer",
    emergencytype: "Emergency Type",
    department: "Scope",
    subdepartment: "Department",
  };

  const headers = [];

  Object.keys(visibleColumns).forEach((key) => {
    if (visibleColumns[key]) {
      headers.push(headerObj[key]);
    }
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  function filterByDateRange(rows, startDate, endDate) {
    if (!startDate || !endDate) return rows;

    const start = new Date(startDate).setHours(0, 0, 0, 0);
    const end = new Date(endDate).setHours(23, 59, 59, 999);

    return rows.filter((row) => {
      const [day, month, year] = row.date.split("/");
      const rowDate = new Date(year, month - 1, day).getTime();

      return rowDate >= start && rowDate <= end;
    });
  }

  function resetDateHandler() {
    setStartDate("");
    setEndDate("");
  }

  const filteredRows = filterByDateRange(rows, startDate, endDate)

  const [searchText, setSearchText] = useState("")
  const searchedRows = filteredRows.filter(el => {
    if (searchText === "") return true
    return el.name.toLowerCase().includes(searchText.toLowerCase())
  })

  return (
    <ImReportContainer>
      <MainScreenContainer loading={apiStatus === "loading"} heading={heading}>
        {apiStatus === "error" && <ApiErrorTableMessage />}
        {apiStatus === "success" && (
          <>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" gap={2} alignItems="center">
                <CalenderMenu
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  onReset={resetDateHandler}
                />
                <SearchBar value={searchText} onChange={e => setSearchText(e.target.value)} />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
              >
                <TableFilterBtn
                  anchorEl={columnAnchorEl}
                  onClose={handleColumnClose}
                  onClick={handleColumnClick}
                  columnDefinitions={columnDefinations}
                  visibleColumns={visibleColumns}
                  onChange={toggleColumnVisibility}
                />
                <ExportBtn
                  rows={selectedData}
                  isSelected={true}
                  headers={headers}
                  fileName={heading}
                />
              </Stack>
            </Stack>
            <DataTable
              rows={searchedRows}
              columns={columns}
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              onRowsSelectionHandler={onRowsSelectionHandler}
              whichPage={page}
            />
          </>
        )}
      </MainScreenContainer>
    </ImReportContainer>
  )
}

const columnDefinations = [
  { field: "date", headerName: "DATE", width: 120 },
  { field: "name", headerName: "PRODUCT NAME", width: 150 },
  { field: "department", headerName: "SCOPE", width: 150 },
  { field: "subdepartment", headerName: "DEPARTMENT", width: 210 },
  { field: "quantity", headerName: "QUANTITY", width: 150 },
  { field: "category", headerName: "CATEGORY", width: 150 },
  { field: "manufacturer", headerName: "MANUFACTURER", width: 150 },
  { field: "emergencytype", headerName: "EMERGENCY TYPE", width: 150 },
];

const visibleColumnsInitial = {
  name: true,
  quantity: true,
  category: true,
  manufacturer: true,
  emergencytype: true,
  date: true,
  department: true,
  subdepartment: true,
}
