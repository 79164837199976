import * as React from "react";
import LoaderOverlay from "../../Loader/LoaderOverlay.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
  Stack,
} from "@mui/material";
// import Button from "@mui/material/Button";
import "./home.css";

import axios from "axios";

import { useState } from "react";
import MainScreenContainer from "../../UI/MainScreenContainer.js";
import DataTable, { TableFilterBtn } from "../../UI/DataTable.js";
import ExportBtn from "./ui/ExportBtn.js";

function createData(
  _id,
  name,
  type,
  batchno,
  manufacturer,
  category,
  unitcost,
  totalquantity,
  gst,
  grandtotal,
  emergencytype,
  productid
) {
  return {
    _id,
    name,
    type,
    batchno,
    manufacturer,
    category,
    unitcost,
    totalquantity,
    gst,
    grandtotal,
    emergencytype,
    productid
  };
}

function AvailaibleProduct({ hospitalid }) {
  const [rows, setRows] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  let [loading, setLoading] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStockAndProductData = async () => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}aggregatedstocks/${hospitalid}`;
      const { data } = await axios.get(url);
      setStocks(data.documents);
      setLoading(false);
      // Create rows from stocks and set them in the state
      const newRows = data.documents.map((stock) =>
        createData(
          stock._id,
          stock.productDetails.name,
          stock.productDetails.producttype,
          stock.batchno,
          stock.productDetails.manufacturer,
          stock.productDetails.category,
          stock.unitcost,
          stock.totalquantity,
          stock.gst,
          stock.grandtotal,
          stock.productDetails.emergencytype,
          stock.productDetails._id
        ),
      );
      setRows(newRows);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getStockAndProductData();
  }, []);


  const [columnAnchorEl, setColumnAnchorEl] = useState(null);

  const handleColumnClose = () => {
    setColumnAnchorEl(null);
  };

  const handleColumnClick = (event) => {
    setColumnAnchorEl(event.currentTarget);
  };

  const toggleColumnVisibility = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };
  const [colMenuValues, setColMenuValues] = useState({});
  const [count, setCount] = useState(0);

  const [visibleColumns, setVisibleColumns] = useState({
    imnam: true,
    name: true,
    manufacturer: true,
    category: true,
    totalquantity: true,
    emergencytype: true,
    type: true,
    batchno: true,
    gst: true,
    unitcost: true,
    grandtotal: true
  });


  const selectedData = [];
  if (count !== 0 && count.size !== 0) {
    for (const entry of count.values()) {
      const row = rows.find((r) => r._id === entry);
      if (row) {
        const a = [];
        Object.keys(visibleColumns).forEach((key) => {
          if (visibleColumns[key]) {
            a.push(row[key]);
          }
        });

        selectedData.push(a);
      }
    }
  }

  const headerOj = {
    name: "Product name",
    imname: "Inventory Manager",
    manufacturer: "Manufacturer",
    type: "Type",
    category: "Category",
    totalquantity: "Quantity",
    emergencytype: "Emergency Type",
    batchno: "Batch no",
    gst: "GST",
    unitcost: "Unit cost",
    grandtotal: "Grand total"
  };
  const headers = [];

  Object.keys(visibleColumns).forEach((key) => {
    if (visibleColumns[key]) {
      headers.push(headerOj[key]);
    }
  });

  const columns = columnDefinitions.filter((col) => visibleColumns[col.field]);

  const [rowModesModel, setRowModesModel] = useState({});
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const onRowsSelectionHandler = (id) => {
    const selectedIDs = new Set(id);
    setCount(selectedIDs);
  };



  return (
    <MainScreenContainer loading={loading} heading="Available Products">
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <TableFilterBtn
          anchorEl={columnAnchorEl}
          onClose={handleColumnClose}
          onClick={handleColumnClick}
          columnDefinitions={columnDefinitions}
          visibleColumns={visibleColumns}
          onChange={toggleColumnVisibility}
        />
        <ExportBtn
          rows={selectedData}
          isSelected={true}
          headers={headers}
          fileName="Available_product"
        />
      </Stack>
      <DataTable
        rows={rows}
        columns={columns}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowsSelectionHandler={onRowsSelectionHandler}
        whichPage="avail"
        colMenuValues={colMenuValues}
        setColMenuValues={setColMenuValues}
      />
    </MainScreenContainer>
  );
}

export default AvailaibleProduct;

const width = 150
const disableColumnMenu = true

const columnDefinitions = [
  { field: "name", headerName: "PRODUCT NAME", headerAlign: "left", align: "left", width, disableColumnMenu },
  { field: "type", headerName: "TYPE", headerAlign: "left", align: "left", width, disableColumnMenu },
  { field: "batchno", headerName: "BATCH NO", headerAlign: "left", align: "left", width, disableColumnMenu },
  { field: "manufacturer", headerName: "MANUFACTURER", headerAlign: "left", align: "left", width, disableColumnMenu },
  { field: "category", headerName: "CATEGORY", headerAlign: "left", align: "left", width, disableColumnMenu },
  { field: "unitcost", headerName: "UNIT COST", headerAlign: "left", align: "left", width, disableColumnMenu },
  { field: "totalquantity", headerName: "TOTAL QUANTITY", headerAlign: "left", align: "left", width, disableColumnMenu },
  { field: "gst", headerName: "GST", headerAlign: "left", align: "left", width, disableColumnMenu },
  { field: "grandtotal", headerName: "GRAND TOTAL", headerAlign: "left", align: "left", width, disableColumnMenu },
  { field: "emergencytype", headerName: "EMERGENCY TYPE", headerAlign: "left", align: "left", width, disableColumnMenu },
]

